import { getAuthToken } from '@/firebase/client';
import { getSessionCompanyId } from '@/utils/getCompanyId';
import axios from 'axios';

export const axiosClient = axios.create();
axiosClient.interceptors.request.use( async ( config ) => {
	if ( config.url && config.url.startsWith( '/' ) ) {
		config.url = `${process.env.NEXT_PUBLIC_SERVER_URL}${config.url}`;
	}
	if ( !config.headers ) config.headers = {};
	const companyId = getSessionCompanyId() || '';
	if ( !config.headers.company ) config.headers.company = companyId;
	if ( !config.headers.authorization ) config.headers.authorization = await getAuthToken();
	return config;
} );
