import { AddRounded as AddRoundedIcon, RemoveRounded as RemoveRoundedIcon } from '@mui/icons-material';
import type { StackProps, TextFieldProps } from '@mui/material';
import { IconButton, InputLabel, Paper, Stack } from '@mui/material';
import { useField } from 'formik';
import { Fragment } from 'react';
import FormTextField from './textField';

export default function InputStepper( {
	name,
	width,
	minVal = 0,
	maxVal = 0,
	stackProps,
	...props
}: {
	name: string,
	width?: number | string,
	minVal?: number,
	maxVal?: number,
	stackProps?: StackProps
} & TextFieldProps ) {
	const [ , { value = null }, { setValue } ] = useField<number>( name );
	
	return (
		<Fragment>
			{props.label && (
				<InputLabel
					sx={{
						position     : 'relative',
						maxWidth     : '50%',
						transform    : 'none',
						fontWeight   : 500,
						color        : 'text.primary',
						paddingBottom: '5px',
						paddingLeft  : '3px',
					}}>
					{props.label}
				</InputLabel>
			)}
			<Stack
				direction='row'
				alignItems='center'
				component={Paper}
				spacing={1}
				{...stackProps}
				sx={{
					'p'                               : .5,
					'width'                           : width || 'fit-content',
					'border'                          : 'none',
					'height'                          : 36,
					'.MuiOutlinedInput-input'         : { my: -.5 },
					'.MuiOutlinedInput-notchedOutline': { borderColor: 'alpha.primary' },
					'bgcolor'                         : 'alpha.primary',
					...stackProps?.sx,
					'.MuiIconButton-root'             : {
						// 'bgcolor'     : 'primary.main',
						'borderRadius': '50%',
						'width'       : 24,
						'height'      : 24,
						'SVG'         : { color: 'primary.main', fontSize: 18 },
						':hover'      : { bgcolor: 'alpha.primary' },
						...props.sx?.[ '.MuiIconButton-root' ],
					},
				}}>
				<IconButton
					color='primary'
					onClick={( e ) => {
						e.stopPropagation();
						if ( props.disabled || minVal !== 0 && value === minVal ) return;
						setValue( +Math.max( minVal, value - 1 ).toFixed( 3 ) );
					}}>
					<RemoveRoundedIcon/>
				</IconButton>
				<FormTextField
					fullWidth
					name={name}
					format={( value ) => {
						// First convert to number and ensure it's a valid number
						const numValue = typeof value === 'string' ? parseFloat( value ) : value;
						if ( isNaN( numValue ) ) return minVal;
						
						// Then apply min and max constraints
						const constrained = Math.min( maxVal !== 0 ? maxVal : Infinity, Math.max( minVal, numValue ) );
						return +constrained.toFixed( 3 );
					}}
					inputProps={{ style: { textAlign: 'center' } }}
					onFocus={( e ) => e.target.select()}
					{...props}
					label={null}
				/>
				<IconButton
					color='primary'
					onClick={( e ) => {
						e.stopPropagation();
						if ( props.disabled || maxVal !== 0 && value === maxVal ) return;
						setValue( +( value + 1 ).toFixed( 3 ) );
					}}>
					<AddRoundedIcon/>
				</IconButton>
			</Stack>
		</Fragment>
	);
}
