import CustomCheckBox from '@/components/customCheckBox';
import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import { lineItemTotalCalculation } from '@/pages/dashboard/commerce/form/lineItemForm/calculation';
import { invoiceLineItemsSelection } from '@/pages/p/commerce/invoiceEstimate/clientView/invoiceSelection';
import useUserInfo from '@/providers/auth/useUserInfo';
import { LineItem, Order, SimpleLineItem } from '@/types/schema';
import {
	Box,
	Chip,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import axios from 'axios';
import { intersectionWith, isString, map, sum } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../helpers/currencyFormat';

export default function LineItemsToRefund( {
	invoice,
	lineItems,
	selectionsMenu,
}: {
	invoice: Order,
	lineItems: SimpleLineItem[],
	selectionsMenu: string[]
} ) {
	
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const selections = selectionsMenu ? [ ...selectionsMenu ] : invoice.company.metadata?.selectionsMenu || [];
	
	const lineItemCategories: string[] = lineItems
		?.map( ( lineItem ) => lineItem.category?.name || undefined )
		?.filter( ( name, index, self ) => self.indexOf( name ) === index ) || [];
	
	const {
		      showUnit,
		      showName,
		      showTotal,
		      showPrice,
		      showQuantity,
		      showCategory,
		      showDescription,
		      showCode,
		      showSku,
		      expandedLines,
		      showTaxedLineItems,
	      } = invoiceLineItemsSelection( selections );
	
	return (
		<Form<{ selectedLineItemIds: string[] }>
			initialValues={{ selectedLineItemIds: [] }}
			onSubmit={async ( values ) => {
				try {
					enqueueSnackbar( 'Please wait while the refund is in process....', { variant: 'info' } );
					const { data } = await axios.post( '/api/processor/manage/refundLineItem', {
						orderExternalId    : invoice.externalId,
						lineItemExternalIds: values.selectedLineItemIds,
						gatewayId          : invoice.gateway?.id || invoice.companyLocation?.gateway?.id,
					} );
					if ( data?.refunded ) {
						await axios.post( '/api/processor/manage/importOrder', {
							id     : invoice.id,
							staffId: staff?.id,
						} ).catch( () => [] );
					}
					
				} catch ( e ) {
					const cloverErrors = e?.response.data?.cloverErrors;
					if ( cloverErrors ) {
						throw isString( cloverErrors )
							? cloverErrors
							: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred - Clover.com';
					} else {
						enqueueSnackbar( t( 'commerce:sync-fail' ), { variant: 'default' } );
					}
					
					console.error( e );
					throw e;
				}
				
			}}>
			{( formik ) => {
				const selectedLineItemIds = formik.values.selectedLineItemIds;
				const selectedLineItems = intersectionWith( lineItems, selectedLineItemIds, ( a,
					b ) => a.externalId === b );
				
				const total = selectedLineItems?.reduce( ( sum,
					lineItem ) => sum + lineItemTotalCalculation( lineItem as LineItem )?.total, 0 );
				
				return (
					<ModalFormWrapper
						title='Refund Line Items'
						saveButtonText={`Refund ${total ? currencyFormat( total ) : ''}`}
						saveButtonProps={{ disabled: !selectedLineItemIds?.length }}>
						<TableContainer
							sx={{
								'borderRadius'            : 2,
								'border'                  : 1,
								'borderColor'             : 'divider',
								'.MuiTypography-root'     : { fontSize: { xs: '12px', sm: '1.2333rem' } },
								'tbody > tr:last-child td': { borderBottom: 0 },
							}}>
							<Table size='small' cellSpacing={2}>
								<TableHead sx={{ bgcolor: 'divider', display: 'table-row-group' }}>
									<TableRow>
										<TableCell></TableCell>
										<TableCell sx={{ width: '30%' }}>
											{t( 'common:product' )}
										</TableCell>
										<TableCell
											colSpan={1}>
											{showDescription && t( 'common:description' )}
										</TableCell>
										<TableCell width='10%'>
											{showUnit && t( 'common:unit' )}
										</TableCell>
										<TableCell width='8%'>
											{showPrice && t( 'common:price' )}
										</TableCell>
										<TableCell width='8%'>
											{showQuantity && t( 'common:quantity' )}
										</TableCell>
										<TableCell align='right' colSpan={2}>
											{t( 'common:total' )}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{map( lineItemCategories, ( category ) => (
										<Fragment key={category || 'other'}>
											{showCategory && category && (
												<TableRow>
													<TableCell></TableCell>
													<TableCell sx={{ pb: 0, pt: 1, pl: 1 }}>
														<Typography
															sx={{
																color     : 'text.secondary',
																fontSize  : '12px !important',
																fontWeight: '500',
															}}>
															{category}
														</Typography>
													</TableCell>
												</TableRow>
											)}
											{lineItems.filter( ( lineItem ) => lineItem.category?.name === category )
												.map( ( lineItem, index ) => {
													const hasExternalTax = lineItem.prices?.find( ( price ) => price.metadata?.useTax && price.value > 0 );
													
													return (
														<Fragment key={index}>
															<TableRow>
																<TableCell
																	sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	<CustomCheckBox
																		disabled={lineItem.metadata?.refunded}
																		checked={selectedLineItemIds.includes( lineItem.externalId ) ?? false}
																		onChange={( e, checked ) => {
																			let newIds: string[];
																			
																			if ( checked ) {
																				newIds = [ ...selectedLineItemIds, lineItem.externalId ];
																			} else {
																				newIds = selectedLineItemIds.filter( ( id ) => id !== lineItem.externalId );
																			}
																			formik.setFieldValue( 'selectedLineItemIds', newIds );
																		}}
																	/>
																</TableCell>
																<TableCell sx={{ py: 0 }}>
																	<Stack direction='row' spacing={1}>
																		<Typography>
																			{showName
																				? `${lineItem.name} ${showTaxedLineItems && ( lineItem.tax > 0 || hasExternalTax )
																					? '(T)'
																					: ''}`
																				: ''}
																		</Typography>
																		{lineItem.metadata?.refunded && (
																			<Chip
																				variant='alpha'
																				color='warning'
																				label={t( 'common:refunded' )}
																			/>
																		)}
																	</Stack>
																</TableCell>
																<TableCell colSpan={1} sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	{showDescription ? lineItem.description || '' : ''}
																</TableCell>
																<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	{showUnit ? lineItem.unit : ''}
																</TableCell>
																<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	{showPrice ? currencyFormat( lineItem.price ) : ''}
																</TableCell>
																<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	{showQuantity ? lineItem.quantity : ''}
																</TableCell>
																<TableCell
																	align='right'
																	colSpan={2}
																	sx={{ py: !expandedLines ? 0 : 'auto' }}>
																	{showTotal
																		? currencyFormat( lineItem.price * lineItem.quantity )
																		: null}
																</TableCell>
															</TableRow>
															{/* Modifier Groups*/}
															{lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
																?.map( ( modifier, index ) => (
																	<Fragment key={index}>
																		<TableRow
																			sx={{
																				'.MuiTableCell-root': {
																					color     : 'text.secondary',
																					fontSize  : 12,
																					py        : 0,
																					lineHeight: !expandedLines ? 1 : 'auto',
																				},
																			}}>
																			<TableCell colSpan={5}>
																				{`${modifier.name} ${modifier.isPercent
																					? `(${modifier.value}%)`
																					: ` - ${currencyFormat( modifier.value )} ${lineItem.metadata?.[ modifier.externalId ]
																						? `x ${lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity}`
																						: `x ${lineItem.quantity}`}`}`}
																			</TableCell>
																			<TableCell
																				align='right'>
																				{modifier.isPercent
																					? currencyFormat( modifier.value * ( lineItem.price * lineItem.quantity / 100 ) )
																					: currencyFormat( modifier.value * ( lineItem.metadata?.[ modifier.externalId ] || 1 ) * lineItem.quantity )}
																			</TableCell>
																		</TableRow>
																	</Fragment>
																) ) )}
															{/* LineItem Prices */}
															{lineItem.prices?.filter( ( price ) => !price.metadata?.externalTax )
																?.map( ( fee, index ) => {
																	const modifierTotal = lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
																		?.reduce( ( total,
																			modifier ) => total + modifier.value * lineItem.metadata?.[ modifier.externalId ], 0 ) );
																	return (
																		<Fragment key={index}>
																			<TableRow
																				sx={{
																					'.MuiTableCell-root': {
																						color: 'text.secondary',
																						py   : .2,
																					},
																				}}>
																				<TableCell colSpan={5}>
																					{`${fee.name} ${fee.isPercent
																						? `(${fee.value}%)`
																						: ` (${currencyFormat( fee.value )}) ${fee.quantity > 1
																							? `x ${fee.isPercent
																								? fee.quantity
																								: fee.quantity * lineItem.quantity}`
																							: `x ${fee.isPercent ? '1' : lineItem.quantity}`}`
																					}`}
																				</TableCell>
																				<TableCell align='right'>
																					{fee.isPercent
																						? currencyFormat( fee.value / 100 * ( lineItem.price * lineItem.quantity + sum( modifierTotal ) ) )
																						: currencyFormat( fee.value * ( fee.quantity || 1 ) * lineItem.quantity )}
																				</TableCell>
																			</TableRow>
																		</Fragment>
																	);
																} )}
															{/*Line Item Image and Description*/}
															{!lineItem.image && !showCode && !showSku && !showDescription
																? undefined
																: (
																	<Fragment>
																		{Boolean( lineItem.image || lineItem.description || lineItem.code ) && (
																			<TableRow>
																				<TableCell colSpan={6} sx={{ pt: 0 }}>
																					<Stack direction='row'>
																						{Boolean( lineItem.code ) && (
																							<Box
																								sx={{
																									whiteSpace: 'pre-line',
																									color     : 'text.secondary',
																									lineHeight: 1.2,
																								}}>
																								<Stack direction='row' spacing={1}>
																									{showCode && lineItem.code && (
																										<Typography color='text.secondary'>
																											#{lineItem.code}
																										</Typography>
																									)}
																									{showSku && lineItem.sku && (
																										<Typography color='text.secondary'>
																											{lineItem.sku}
																										</Typography>
																									)}
																								</Stack>
																							</Box>
																						)}
																					</Stack>
																				</TableCell>
																			</TableRow>
																		)}
																	</Fragment>
																)}
														</Fragment>
													);
												} )}
										</Fragment>
									) )}
								</TableBody>
							</Table>
						</TableContainer>
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
	
}
