import { axiosClient } from '@/data/axios';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from 'rooks';

type Uom = {
	id: string,
	selected?: boolean,
	quantity?: number
};

type Props = {
	disabled?: boolean,
	externalId?: string,
	uom?: Uom,
	onMutate?: () => void,
	onSuccess?: () => void,
	onFinish?: () => void
};

function StockQuickEdit( {
	disabled = false, externalId, uom, onMutate, onSuccess, onFinish,
}: Omit<Props, 'uom'> & { uom: Uom } ) {
	const confirmDialog = useConfirmDialog();
	const uomQuantity = uom.quantity || 0;
	const [ quantity, setQuantity ] = useState( uomQuantity );
	const [ isEdit, setIsEdit ] = useState( false );
	const [ isLoading, setIsLoading ] = useState( false );
	const { enqueueSnackbar } = useSnackbar();
	const container = useRef<HTMLDivElement>( null );
	const inputRef = useRef<HTMLInputElement>( null );
	
	useOutsideClick( container, () => {
		toggleEdit( false );
	} );
	
	async function quickUpdateUomStock() {
		setIsLoading( true );
		onMutate?.();
		try {
			if ( uom.selected && externalId ) {
				const value = await confirmDialog( {
					title  : 'This is a Clover item',
					message: <Typography>Are you sure overwriting the Clover item stock
						to <Box component='span' color='error.main'>{quantity}</Box> ?
					</Typography>,
					closeOnClickOutside: true,
				} );
				if ( !value ) return;
			}
			await axiosClient.post( '/api/user/inventory/updateUom', {
				externalId,
				diff           : quantity - uomQuantity,
				action         : 'Commerce Stock Quick Edit',
				overwriteClover: true,
				uom            : {
					id      : uom.id,
					selected: uom.selected || false,
					quantity: uomQuantity,
				},
			} );
			setIsEdit( false );
			onSuccess?.();
		} catch ( e ) {
			enqueueSnackbar( e.response?.data?.message ?? e.message, { variant: 'error' } );
		} finally {
			onFinish?.();
			setIsLoading( false );
		}
	}
	
	useEffect( () => {
		setQuantity( uom.quantity || 0 );
	}, [ uom.quantity ] );
	
	function toggleEdit( open?: boolean ) {
		if ( disabled ) return;
		const isOpen = open ?? !isEdit;
		setIsEdit( isOpen );
		if ( isOpen ) {
			setTimeout( () => {
				inputRef.current?.focus();
			}, 100 );
		}
	}
	
	return (
		<Stack
			ref={container}
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			gap={1}
			py={0.5}
			minWidth='90px'>
			{isEdit
				? (
					<TextField
						inputRef={inputRef}
						sx={{
							input: {
								py: 0.5,
								pl: 1,
							},
						}}
						disabled={disabled}
						type='number'
						value={quantity}
						onChange={( e ) => setQuantity( +e.target.value )}
					/>
				)
				: <Typography>{uomQuantity}</Typography>}
			{isLoading
				? <Stack alignItems='center'><CircularProgress size={16}/></Stack>
				: isEdit
					? (
						<DoneOutlineIcon
							color='success'
							fontSize='small'
							cursor='pointer'
							onClick={quickUpdateUomStock}
						/>
					)
					: (
						<EditIcon
							id={`uom-${uom.id}`}
							color='primary'
							fontSize='small'
							cursor='pointer'
							onClick={() => {
								toggleEdit();
							}}
						/>
					)}
		</Stack>
	);
}

function Wrapper( { uom, ...props }: Props ) {
	if ( !uom ) return <Typography>-</Typography>;
	return <StockQuickEdit {...props} uom={uom}/>;
}

export default Wrapper;
