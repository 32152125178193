import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CategoryWrite } from '@/data/management/category.graphql';
import { ItemWrite } from '@/data/management/item.graphql';
import { MenuRead, MenuWrite } from '@/data/management/menu.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import idPick from '@/helpers/idPick';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import {
	Category,
	Menu,
	MutationCategoryWriteArgs,
	MutationItemWriteArgs,
	MutationMenuWriteArgs,
	QueryCategoriesReadArgs,
	Uom,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, pick } from 'lodash-es';
import { ComponentType, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import * as yup from 'yup';
import VendorMenus from '../[id]/menus';
import VendorAddress from './address';
import { getAddressErrors, hasFullAddress, hasNoAddress } from './addressCheck';
import VendorFormDetails from './details';
import VendorFormLocations from './locations';
import VendorFormCSVUpload from './vendorFormCSVUpload';

export default function VendorForm( {
	id,
	onSubmit,
	Wrapper = Fragment,
}: {
	id?: string,
	onSubmit?: ( menu: Menu ) => void,
	Wrapper?: ComponentType<{ name: string, children: ReactNode }>
} ) {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	
	const validationSchema = yup.object().shape( {
		vendorName: yup
			.string()
			.required( t( 'management:enter-vendor-company-name' ) )
			.max( 64, t( 'management:company-is-too-long' ) ),
		addresses: yup
			.array()
			.nullable().optional()
			.of( yup
				.object()
				.shape( {
					line1: yup
						.string()
						.max( 250, t( 'common:house-accounts-address-long' ) ),
					line2: yup
						.string()
						.nullable().optional()
						.max( 64, t( 'common:floor-ste-is-too-long' ) ),
					city: yup
						.string()
						.max( 64, t( 'common:house-accounts-city-long' ) ),
					state: yup
						.string()
						.max( 64, t( 'common:house-accounts-state-long' ) ),
					country: yup
						.string()
						.max( 64, t( 'common:house-accounts-country-long' ) ),
					postalCode: yup
						.string()
						.max( 64, t( 'common:house-accounts-postal-long' ) ),
				} ),
			),
	} );
	return (
		<CheckPermissions
			redirect='/dashboard/management/vendors'
			permissions={permissions.vendors.write}>
			<FormGraphqlProvider<Menu & { imported: Uom[] }>
				id={id}
				queryKey='vendor'
				query={MenuRead}
				validationSchema={validationSchema}
				initialValues={() => ( { servingLocations: [], uoms: [], imported: [], active: true } )}
				onSubmit={async ( { id, ...values }, { setErrors } ) => {
					const address = values.addresses?.[ 0 ];
					if ( hasNoAddress( address ) ) {
						values.addresses = null;
					} else {
						if ( !hasFullAddress( address ) ) {
							setErrors( getAddressErrors( address ) );
							return;
						}
					}
					// imported is for imported uoms
					const uoms: Uom[] = values?.uoms || [];
					const importedUoms: Uom[] = values?.imported;
					const newUoms: Uom[] = [];
					
					const categories = {};
					
					const { categoriesRead }: {
						categoriesRead: { items: Pick<Category, 'id' | 'name'>[] }
					} = await queryGraphQL<QueryCategoriesReadArgs>( {
						query: gql`
						query CategoriesRead_374c($options: FilterOptions) {
							categoriesRead(options: $options) {
								items {
									id
									name
								}
							}
						}
					`,
						variables:
							importedUoms?.length > 0
								? { options: { filter: { name: { $in: importedUoms?.map( ( uom ) => uom.item.categories?.[ 0 ]?.name ) } } } }
								: {},
					} );
					const foundCategories = categoriesRead.items;
					for ( const uom of importedUoms ?? [] ) {
						if ( !uom.item.categories?.[ 0 ] ) continue;
						const category = categoriesRead.items.find( ( c ) => c.name === uom.item.categories?.[ 0 ].name );
						if ( category ) {
							categories[ uom.id ] = category.id;
						} else {
							const { categoryWrite } = await mutateGraphQL<MutationCategoryWriteArgs>( {
								mutation : CategoryWrite,
								variables: {
									input: { name: uom.item.categories[ 0 ]?.name || 'N/A' },
								},
							} );
							foundCategories.push( categoryWrite );
							categories[ uom.id ] = categoryWrite.id;
						}
					}
					
					if ( !isEmpty( importedUoms ) ) {
						for ( const uom of importedUoms ) {
							const { itemWrite } = await mutateGraphQL<MutationItemWriteArgs>( {
								mutation : ItemWrite,
								variables: {
									id    : validate( uom?.item?.id ) ? uom.item.id : undefined,
									method: uom?.item?.id ? 'Menu Item Created' : 'Saved Changes',
									input : {
										name       : uom.item?.name || null,
										description: uom.item?.description || null,
										uoms       : [ {
											...idPick( uom, [ 'sku', 'name', 'cost', 'quantity', 'code' ] ),
											price   : uom.price,
											selected: true,
										} ],
										categories: categories[ uom.id ] ? [ categories[ uom.id ] ] : null,
									},
									
								},
							} );
							newUoms.push( itemWrite.uoms[ 0 ] as Uom );
						}
						
					}
					const { menuWrite } = await mutateGraphQL<MutationMenuWriteArgs>( {
						mutation : MenuWrite,
						variables: {
							id,
							method: id ? 'Saved Changes' : 'New Vendor',
							input : {
								...pick( values, [ 'vendorName', 'vendorContact', 'vendorEmail', 'vendorPhone', 'active' ] ),
								servingLocations: values.servingLocations?.map( ( { id } ) => id ),
								uoms            : [ ...newUoms, ...uoms ].map( ( { id } ) => id ),
								addresses       : values.addresses?.map( ( address ) => idPick( address, [
									'line1',
									'line2',
									'city',
									'state',
									'postalCode',
									'country',
									'lat',
									'lng',
								] ) ) || [],
								note: values.note,
							},
						},
					} );
					
					onSubmit?.( menuWrite );
					await queryClient.invalidateQueries( [ 'company' ] );
				}}>
				<Wrapper name='Vendor'>
					<VendorFormDetails/>
					<VendorAddress/>
					<VendorFormLocations/>
					<VendorFormCSVUpload/>
					<VendorMenus/>
				</Wrapper>
			</FormGraphqlProvider>
		</CheckPermissions>
	);
}
